import Cookies from 'js-cookie';

function setToken(token) {
    Cookies.set('token', token, {path: '/' });
}

function getToken() {
    return Cookies.get('token') || '';
}

export {
    setToken,
    getToken
}